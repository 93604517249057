/* You can add global styles to this file, and also import other style files */
.tiler {
  width: 260px;
  height: 180px;
  background-color: #F58634;
  display: inline-block;
  background-size: cover;
  position: relative;
  cursor: pointer;
  transition: all 0.4s ease-out;
  box-shadow: 0px 35px 77px -17px rgba(0, 0, 0, 0.44);
  overflow: hidden;
  color: white;
  font-family: 'Roboto';
}

.tiler img {
  display: block;
  margin: auto;
  max-width: 100%;
  visibility: visible;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: all 0.4s ease-out;
}

.tiler .cell-grid {
  background-position: center center;
  margin: auto;
  position: fixed;
  top: 1em;
  bottom: 1em;
  left: 1em;
  right: 1em;
  z-index: 10;

  max-width: 1000px;
  max-height: 600px;

  perspective: 30px;

  // &:before {
  //   content: '';
  //   display: block;
  //   visibility: visible;
  //   position: absolute;
  //   top: 0; right: 0; bottom: 0; left: 0;
  //   margin: auto;
  //   box-shadow: 0 1em 2em rgba(0,0,0,0.3);
  // }
}

.tiler .cell {
  pointer-events: none;
  opacity: 0;

  transform: translateZ(-15px);
  transform-style: preserve-3d;

  transition-property: transform, opacity;
  transition-duration: 0.5s, 0.4s;
  transition-timing-function: cubic-bezier(0.65, 0.01, 0.15, 1.33); //, linear;

  /* The center character index */
  --center-x: calc((var(--col-total) - 1) / 2);
  --center-y: calc((var(--row-total) - 1) / 2);

  /* Offset from center, positive & negative */
  --offset-x: calc(var(--col-index) - var(--center-x));
  --offset-y: calc(var(--row-index) - var(--center-y));

  /* Absolute distance from center, only positive */
  --distance-x: calc((var(--offset-x) * var(--offset-x)) / var(--center-x));

  /* Absolute distance from center, only positive */
  --distance-y: calc((var(--offset-y) * var(--offset-y)) / var(--center-y));

  transition-delay: calc(0.1s * var(--distance-y) + 0.1s * var(--distance-x));
}

// .tiler { transition: z-index 0.3s linear; }

.tiler-overlay {
  z-index: 2;
}
.tiler:hover {
  .cell {
    // pointer-events: auto;
    transform: scale(1);
    opacity: 1;
  }
}

